import React from 'react';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import {withGlobalPropsProvider} from '../providers/globalPropsProvider';
import {getRuntimeStyleParams} from './styleParamsService';
import {withDefaultStyleParams} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/withDefaultStyleParams';
import {FormFactor} from '../constants';
import {withStrictMode} from '@wix/wixstores-client-common-components/dist/es/src/HOC/strictMode';

export const bootstrapApp = (Component: React.ComponentType) => {
  let wrappedComponent;

  wrappedComponent = withDefaultStyleParams(
    withStyles(withStrictMode(withGlobalPropsProvider(Component)), {strictMode: true}),
    ({
      host: {
        style: {styleParams},
        formFactor,
      },
      widgetPreset,
    }) => {
      const mobileOverrides = {};
      return {
        defaults: getRuntimeStyleParams(styleParams, {
          formFactor,
          widgetPreset,
        }),
        ...(formFactor === FormFactor.Mobile ? {overrides: mobileOverrides} : {}),
      };
    }
  );

  return wrappedComponent;
};
